var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-full h-full border border-brown-grey flex flex-col flex-nowrap",
    },
    [
      _c("div", { staticClass: "w-full flex-auto" }, [
        _c(
          "div",
          { staticClass: "relative w-full h-full min-h-0 overflow-y-auto" },
          [
            _c("div", { staticClass: "absolute inset-0" }, [
              _c(
                "div",
                { staticClass: "w-full" },
                [
                  _vm.pdfLatest
                    ? _c("vue-pdf-embed", {
                        attrs: {
                          source: _vm.pdfLatest[_vm.$i18n.locale].file.url,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "bg-light-grey py-4 text-center flex-none" },
        [
          _vm.acknowledgeType === "checkbox"
            ? _c(
                "FlCheckbox",
                {
                  attrs: { value: true },
                  model: {
                    value: _vm.mutableValue,
                    callback: function ($$v) {
                      _vm.mutableValue = $$v
                    },
                    expression: "mutableValue",
                  },
                },
                [
                  _c("span", { staticClass: "font-bold" }, [
                    _vm._v(
                      _vm._s(_vm.$t("pages.users.registration.agree_to_terms"))
                    ),
                  ]),
                ]
              )
            : _vm.acknowledgeType === "button"
            ? _c(
                "div",
                { staticClass: "w-5/12 mx-auto" },
                [
                  _c(
                    "LinkButton",
                    {
                      attrs: { classList: "px-1 py-4" },
                      on: { clickButton: _vm.agreeToTerms },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("pages.users.registration.agree_to_terms")
                        )
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "w-5/12 mx-auto mt-4" },
            [
              _c(
                "LinkButton",
                {
                  attrs: { classList: "px-1 py-4" },
                  on: { clickButton: _vm.downloadPdf },
                },
                [_vm._v(_vm._s(_vm.$t("commons.fields.download")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }